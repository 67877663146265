<template>
  <div class="price">
    <Header/>
    <Price/>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Price from '@/components/Price'
export default {
  components: {
    Header,
    Footer,
    Price,
  },
}
</script>

<style lang="scss" scoped>

</style>
